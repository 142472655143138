.product-card{
    $this: &;

    padding: 12px 0 0;
    position: relative;

    @include _992{
        padding: 10px 0 0;
    }

    &[data-state="wishlist"] {
        #{$this}__actions-extra{
            flex-direction: row-reverse;
        }
    }

    &__wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__main{
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    &__thumb{
        display: block;
        height: 260px;
        background-color: #EBEBEB;
        @include _992{
            height: 140px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__labels{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 10px;
        top: 0;
        @include _992{
            left: 5px;
        }
        &-item{
            display: inline-block;
            padding: 6px 16px;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #FFFFFF;
            @include _992{
                font-size: 10px;
                line-height: 14px;
                padding: 2px 4px;
            }
            &--sale{
                background: linear-gradient(90deg, #F40000 0%, #FF7A00 100%);
            }
            &--new{
                background: linear-gradient(90deg, #F40000 0%, #C031F2 100%);
            }
            &--hit{
                background-color: $main-clr-hover;
            }
            &:not(:last-child){
                margin-right: 10px;
                @include _992{
                    margin-right: 4px;
                }
            }
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        padding: 22px 30px;
        flex: 1 0 auto;
        justify-content: space-between;
        @include _992{
            padding: 12px 10px;
        }
    }
    &__price{
        display: block;
        @include _992{
            display: flex;
            flex-direction: column;
        }
        &--unit{
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            @include _992{
                font-size: 18px;
                line-height: 22px;
            }
        }
        &--current{
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            @include _992{
                font-size: 16px;
                line-height: 22px;
            }
        }
        &--old{
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            text-decoration-line: line-through;
            color: #919191;
            margin-right: 20px;
            @include _992{
                margin-right: 0px;
                font-size: 12px;
                line-height: 16px;
            }
        }
        &--request{
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            color: $text-clr;
            margin-right: 20px;
            @include _992{
                margin-right: 15px;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
    &__name{
        display: block;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        padding: 0 0 16px;
        @include _992{
            font-size: 16px;
            line-height: 22px;
            padding: 0 0 12px;
        }
        @include _768{
            font-size: 14px;
            line-height: 18px;
        }
    }
    &__actions{
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 12px;
        @include _992{
            padding: 0;
            flex-direction: column;
        }
    }
    &__link{
        @include _992{
            width: 100%;
        }
        .btn{
            width: 180px;
            height: 60px;
            @include _992{
                height: 40px;
                width: 100%;
            }
        }
    }
    &__buttons{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include _992{
            width: 60%;
            .btn.no-name{
                padding: 14px 12px 0;
            }
        }
        @include _768{
            width: 100%;
        }
    }
}