
.title-underline{
    border-bottom: 3px solid $main-clr;
    padding: 0 0 8px;
}

.content-text{

    &--h1{
        font-weight: 600 !important;
        font-size: 48px !important;
        line-height: 54px !important;
        text-transform: uppercase !important;
        color: $text-clr !important;
        margin: 0 0 40px !important;
        @include _992{
			font-size: 26px !important;
			line-height: 32px !important;
            margin: 0 0 20px !important;
		}
    }

    h1{
        font-weight: 600;
        font-size: 48px;
        line-height: 54px;
        text-transform: uppercase;
        color: $text-clr;
        margin: 0 0 40px;
        @include _992{
			font-size: 26px;
			line-height: 32px;
            margin: 0 0 20px;
		}
    }
    h2{
        font-weight: 700;
        font-size: 42px;
        line-height: 50px;
        color: $text-clr;
        margin: 55px 0 25px;
        &:first-child{
            margin: 0 0 25px;
        }
        @include _992{
			font-size: 22px;
			line-height: 26px;
            margin: 30px 0 20px;
		}
    }
    h3{
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: $text-clr;
        margin: 65px 0 25px;
        letter-spacing: .5px;
        @include _992{
			font-size: 18px;
			line-height: 24px;
            margin: 20px 0 15px;
		}
    }
    h4{
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        color: $text-clr;
        margin: 68px 0 15px;
        letter-spacing: -0.5px;
        @include _992{
			font-size: 16px;
			line-height: 20px;
            margin: 15px 0 15px;
		}
    }
    p, a, li{
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        color: $text-clr;
        @include _992{
			font-size: 12px;
			line-height: 16px;
		}
    }
    p{
        margin: 0 0 25px;
        @include _992{
            margin: 0 0 10px;
        }
    }
    a{
        color: $main-clr;
        transition: color $tr-time, border-color $tr-time;
        border-bottom: 1px solid $main-clr;
        &:hover{
            color: $main-clr-hover;
            border-bottom: 1px solid transparent;
        }
        &:visited{
            color: $main-clr;
            border-bottom: 1px solid $main-clr;
        }
    }
    img{
        max-width: 100%;
        width: 100%;
        margin: 35px 0;
        @include _992{
            margin: 20px 0;
        }
    }

    ol, ul{
        padding-left: 50px;
        margin: 8px 0;
        @include _992{
            padding-left: 15px;
        }
        li{
            padding-left: 10px;
            @include _992{
                padding-left: 0px;
            }
            &:not(:last-child){
                margin: 0 0 15px;
            }

        }
    }

    ol{
        li{
            list-style-type: decimal;
        }
    }
    ul{
        li{
            list-style-type: none;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                background-color: $main-clr;
                border-radius: 50%;
                position: absolute;
                top: 6px;
                left: -24px;
                @include _992{
                    left: -10px;
                    width: 4px;
                    height: 4px;
                }
            }
        }
    }
    .table-wrap{
        max-width: 100%;
        margin: 65px 0;
        @include _992{
            margin: 20px 0;
            overflow: scroll;
        }
    }
    table{
        border: none;
        width: 100%;
        border-collapse: collapse;
        > caption{
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            color: $text-clr;
            text-align: left;
            padding: 0px 0 24px;
            letter-spacing: 0.6px;
            @include _992{
                font-size: 18px;
                line-height: 24px;
                padding: 0 0 15px;
            }
        }

        thead{
            border-bottom: 5px solid $main-clr;
            th{
                font-weight: 700;
                font-size: 28px;
                line-height: 36px;
                color: $text-clr;
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        tbody{
            border: none;
        }

        th, td{
            padding: 20px 24px;
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
            color: $alt-clr;
            text-align: left;
            border: 1px solid $main-clr;
            @include _992{
                font-size: 12px;
                line-height: 16px;
                padding: 15px;
            }
        }

        th{
            font-weight: 700;
        }
    }
}
.element{
    display: flex;
    img{
        width: 230px !important;
        height: auto !important;
        max-width: 30% !important;
    }
}