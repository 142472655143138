.advantages {
  background-image: url(/static/images/brick-background.png);
  background-size: cover;
  padding: 120px 0;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);

    @media(max-width: 1250px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;
    }

    @media(max-width: 420px) {
      grid-template-columns: repeat(1, auto);
    }
  }

  &__part {
    display: grid;
    grid-template-rows: repeat(4, 125px);

    &.one {
      grid-column: 1;
      grid-row: 2;

      @media(max-width: 1250px) {
        padding-right: 20px;
      }

      @media(max-width: 500px) {
        padding-right: 10px;
      }

      @media(max-width: 420px) {
        grid-row: 2;
        grid-column: 1;
      }
    }

    &.two {
      grid-column: 3;
      grid-row: 2;

      @media(max-width: 1250px) {
        grid-column: 2;
      }

      @media(max-width: 420px) {
        grid-row: 4;
        grid-column: 1;
      }
    }

    @media(max-width: 1250px) {
      //grid-template-rows: repeat(4,65px);
      grid-template-rows: repeat(4, auto);
      grid-row-gap: 20px;
    }

    @media(max-width: 420px) {
      grid-template-columns: repeat(1, auto);
    }

  }

  &__image {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }

    @media(max-width: 1250px) {
      grid-row: 1;

      img {
        max-height: 300px;
        object-fit: contain;
      }
    }

    @media(max-width: 420px) {
      grid-row: 3;
      grid-column: 1;
    }
  }

  &__title {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    justify-content: center;
    font-size: calcFluid(24, 57);
    line-height: 100%;
    color: #1a1a1a;
    margin-bottom: 40px;
    text-transform: uppercase;

    @media(max-width: 1250px) {
      grid-column-end: 2;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    min-width: 470px;
    font-size: 21px;

    span {
      color: #c70000;
    }

    &:before {
      content: '';
      background-image: url('/static/images/advantages-tick.svg');
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.no-checkmark {
      &:before {
        width: 0;
        min-width: 0;
        margin: 0;
        height: 0;
      }
    }

    @media(max-width: 1250px) {
      min-width: unset;
      font-size: 17px;
    }

    @media(max-width: 751px) {
      font-size: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c70000;
    color: #ffffff;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    padding: 10px 0;
    width: 100%;

    &:hover {
      background-color: #f40000;
      color: #ffffff;
    }

    @media(max-width: 1250px) {
      font-size: 17px;
    }
    
    @media(max-width: 751px) {
      font-size: 12px;
    }
  }

  @media(max-width: 1250px) {
    padding: 40px 0;
  }
}
