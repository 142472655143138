.leadback{
    padding: 105px 0;
    @include _992{
        padding: 50px 0;
    }
    &-form{
        margin: 40px auto 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        max-width: 540px;
        &--long{
            max-width: 790px;
        }
        .theme-input{
            margin: 0;
        }
        @include _768{
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 50px;
            grid-column-gap: 0px;
            grid-row-gap: 10px;
            margin: 28px auto 10px;
        }
    }
    &-content{
        text-align: center;
        &__title{
            font-weight: 600;
            font-size: 75px;
            text-transform: uppercase;
            color: $text-clr;
            padding: 0 0 12px;
            @include _992{
                font-size: 30px;
                line-height: 38px;
                padding: 0 0 10px;
            }
        }
        &__text{
            font-weight: 400;
            font-size: 22px;
            color: $text-clr;
            @include _992{
                font-size: 14px;
                line-height: 18px;
            }
        }
        &__link{
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: $text-clr;
            @include _992{
                font-size: 12px;
                line-height: 16px;
            }
            a{
                color: $main-clr;
                border-bottom: 1px solid $main-clr;
                transition: border-color $tr-time, color $tr-time;
                &:hover{
                    border-color: $main-clr-hover;
                    color: $main-clr-hover;
                }
            }
        }
    }
}

.footer{
    background-color: #1A1A1A;

    &-main{
        &__container{
            padding: 65px 0;
            display: grid;
            grid-template-columns: 280px 1fr 370px;
        }
    }

    &-logo{
        &__img{
            margin: 0 0 25px;
            img{
                width: 140px;
                height: 130px;
                object-fit: contain;
            }
        }
        &__desc{
            span{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: $alt-clr;
            }
        }
    }

    &-menu{
        grid-column-gap: 60px;
        @include _1200{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        @include _576{
            grid-template-columns: repeat(2, 1fr);
        }
        @include _420{
            grid-template-columns: repeat(1, 1fr);
        }
        &__group{
            @include _992{
                &:not(:last-child){
                    margin: 0 0 25px;
                }
            }
        }
        &__head{
            margin: 0 0 12px;
            a{
                font-size: 22px;
                line-height: 28px;
                color: $alt-clr;
                padding: 8px 0;
                transition: color $tr-time;
                @include _992{
                    font-size: 14px;
                    line-height: 18px;
                    padding: 5px 0;
                }
                &:hover{
                    color: $main-clr-hover;
                }
            }
        }
        &__nav{
            a{
                display: block;
                font-size: 22px;
                line-height: 28px;
                color: #FCFCFC;
                padding: 8px 0;
                transition: color $tr-time;
                @include _992{
                    font-size: 14px;
                    line-height: 18px;
                    padding: 5px 0;
                }
                &:hover{
                    color: $main-clr-hover;
                }
            }
        }
    }

    &-search{
        position: relative;
        background-color: #303030;
        border-radius: 3px;
        padding-left: 25px;
        margin: 0 0 20px;
        input{
            border: none;
            background-color: transparent;
            font-size: 22px;
            line-height: 28px;
            color: $alt-clr;
            height: 60px;
            width: 100%;
            @include _992{
                font-size: 14px;
                line-height: 18px;
                height: 30px;
            }
            &::placeholder{
                font-size: 22px;
                line-height: 28px;
                color: $alt-clr;
                @include _992{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        button{
            position: absolute;
            width: 25px;
            height: 25px;
            right: 16px;
            top: calc(50% - 13px);
            background-color: transparent;
            svg{
                fill: transparent;
                stroke: $alt-clr;
                width: 25px;
                height: 25px;
                @include _992{
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    &-contacts{
        &__item{
            >a{
                display: inline-block;
                padding: 8px 0;
                font-size: 22px;
                line-height: 28px;
                color: #FCFCFC;
                transition: color $tr-time;
                @include _992{
                    padding: 5px 0;
                    font-size: 14px;
                    line-height: 18px;
                }
                &:hover{
                    color: $main-clr-hover;
                }
            }
        }
        &__row{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__social{
            display: grid;
            grid-template-columns: repeat(3, 40px);
            grid-column-gap: 18px;
            @include _1200{
                margin: 30px 0;
            }
            >a{
                svg{
                    width: 40px;
                    height: 40px;
                    fill: #FCFCFC;
                    transition: fill $tr-time;
                    @include _992{
                        width: 30px;
                        height: 30px;
                    }
                }
                &:hover{
                    svg{
                        fill: $main-clr-hover;
                    }
                }
            }
        }
    }

    &-bottom{
        padding: 18px 0;
        border-top: 1.5px solid #303030;
        &__container{
            justify-content: space-between;
            @include _992{
                align-items: center;
                flex-direction: column;
            }
        }
    }

    &-company{
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $alt-clr;
        @include _992{
            font-size: 12px;
            line-height: 16px;
        }
        &--link{
            transition: color $tr-time;
            @include _992{
                text-decoration: underline;
                margin: 12px 0 18px;
            }
            &:hover{
                color: $main-clr-hover;
            }
        }
    }

    &-ps{
        svg{
            width: 150px;
            height: 30px;
            fill: $alt-clr;
            transition: fill $tr-time;
            @include _992{
                width: 85px;
                height: 18px;
            }
        }
        span{
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: $alt-clr;
            transition: color $tr-time;
            @include _992{
                font-size: 12px;
                line-height: 16px;
            }
        }
        &:hover{
            svg{
                fill: $main-clr-hover;
            }
            span{
                color: $main-clr-hover;
            }
        }
    }
}

.footer-mobile{
    &__container{
        padding: 25px 0;
    }  
}