@import 'vendors/swiper.min';


@import 'base/app';
@import 'components/sections/mainbanner-section';
@import 'components/sections/gallery-section';
@import 'components/sections/about-us';
@import 'components/sections/contacts-section';
@import 'components/sections/seo-section';
@import 'components/sections/reviews-section';
@import 'components/sections/suggest-section';
@import 'components/product-card';
//@import 'components/sections/footer-form';
@import 'components/sections/advantages';


.mainslider{
  position: relative;
  margin: 30px 0 105px;
  overflow: hidden;
  @include _1200{
    margin: 0 0 80px;
    .container{
      padding: 0;
    }
    .swiper-wrapper{
      height: 500px;
    }
  }
  @include _992{
    .swiper-wrapper{
      height: 420px;
    }
  }
  @include _768{
    margin: 0 0 40px;
    .swiper-wrapper{
      justify-content: flex-start;
    }
  }
  &-top{
    z-index: 3;
    position: absolute;
    top: 20px;
    a{
      display: inline-block;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 26px;
      transition: color $tr-time;
      &:not(:last-child){
        margin-right: 25px;
      }
      &:hover{
        color: $main-clr-hover;
      }
    }
    @include _1200{
      display: none;
    }
  }
  &-grid{
    display: grid;
    grid-template-columns: 1.65fr 1fr;
    grid-column-gap: 60px;
    @include _1200{
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      height: 100%;
    }
  }
  &-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include _1200{
      padding: 30px 15px;
      height: 100%;
    }
    @include _576{
      justify-content: flex-start;
      text-align: center;
    }
    &__title{
      font-weight: 600;
      font-size: 74px;
      line-height: 78px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      padding: 0 0 10px;
      @include _1200{
        color: #fff;
      }
      @include _992{
        font-size: 28px;
        line-height: 34px;
      }
    }
    &__desc{
      font-size: 22px;
      line-height: 26px;
      @include _1200{
        color: #fff;
      }
      @include _992{
        font-size: 16px;
        line-height: 22px;
      }
    }
    &__button{
      padding: 30px 0 0;
      .btn{
        width: 260px;
        height: 60px;
        @include _992{
          width: 170px;
          height: 45px;
        }
        @include _576{
          margin: 0 auto;
        }
      }
    }
  }
  &-image{
    height: 550px;
    @include _1200{
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -1;
      filter: brightness(.5)
    }
    img{
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 50%;
      @include _1200{
        position: static;
        width: 100%;
      }
    }
  }
  &-nav{
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 50%;
    display: flex;
    justify-content: center;
    @include _1200{
      width: 100%;
      bottom: 30px;
    }
    &__wrap{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__bullets{
      display: flex;
      flex-direction: row;
      align-items: center;
      @include _1200{
        .swiper-pagination-bullet{
          background: #DADADA;
          opacity: 1;
        }
        .swiper-pagination-bullet-active{
          background-color: $main-clr;
        }
        .swiper-navigation{
          border-color: #DADADA;
        }
      }
    }
  }
}


.company{
  max-width: 1920px;
  border-top: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;
  margin: 0 auto;
  overflow: hidden;
  @include _768{
    border-top: unset;
    border-bottom: unset;
  }
  &-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 70px;
    height: 100%;
    @include _992{
      grid-column-gap: 30px;
    }
    @include _768{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &-gallery{
    display: grid; 
    grid-template-columns: 1.3fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 18px 18px; 
    grid-template-areas: 
    "f0 f1"
    "f0 f2"; 
    &__item{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:nth-child(1){
        grid-area: f0;
      }
      &:nth-child(2){
        grid-area: f1;
      }
      &:nth-child(3){
        grid-area: f2;
      }
    }
  }
  &-text{
    &__grid{
      display: grid;
      grid-template-columns: 1fr 230px;
      height: 100%;
      @include _1200{
        grid-template-columns: 1fr;
      }
    }
    &__main{
      padding: 70px 50px 70px 0;
      @include _1200{
        padding: 25px 35px 25px 0;
      }
      @include _768{
        padding: 0 15px 25px;
      }
    }
    &__vertical{
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #DADADA;
      border-right: 1px solid #DADADA;
      @include _1200{
        display: none;
      }
    }
    &__rotate{
      transform: rotate(90deg);
      font-weight: 600;
      font-size: 66px;
      line-height: 72px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #DADADA;
    }
  }
}

.contactsElement--h1 {
  display: block;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
}
