.contacts-section{
    &__grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 45px;
        @include _1200{
            grid-column-gap: 15px;
        }
        @include _576{
            grid-template-columns: 1fr;
            grid-column-gap: 0;
            grid-row-gap: 20px;
        }
    }
}

.contacts-block{
    border: 1px solid #DADADA;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__info{
        flex: 1;
        padding: 45px 65px;
        @include _992{
            padding: 15px 20px;
        }
        >a{
            &:hover{
                color: $main-clr-hover;
            }
        }
        &-item{
            display: block;
            font-size: 22px;
            line-height: 28px;
            color: $text-clr;
            transition: $tr-time;
            b{
                display: block;
                margin-bottom: 10px;
            }
            a:hover{
                color: #c70000;
            }
            &:not(:last-child){
                margin: 0 0 25px;
                @include _992{
                    margin: 0 0 10px;
                }
            }
            @include _992{
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    &__map{
        height: 400px;
        background-image: url('/static/images/placeholder.jpg');
        background-size: cover;
        background-position: center;
        @include _992{
            height: 250px;
        }
    }
}