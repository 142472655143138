.suggest{
    &-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 45px;
        grid-row-gap: 90px;
        @include _1200{
            grid-template-columns: repeat(3, 1fr);
            grid-row-gap: 40px;
            grid-column-gap: 30px;
        }
        @include _768{
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 15px;
        }
    }
}
