.header-mobile{
    //padding: 10px 15px;
    padding: 0 15px 0 15px;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    align-items: center;
    //grid-column-gap: 20px;
    &-logo{
        display: block;
        img{
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
    }
    &-logo-horizontal{
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            max-height: 40px;
            object-fit: contain;
        }
    }
}

.header-search{
    padding-left: 15px;
    display: grid;
    grid-template-columns: 1fr 30px;
    background-color: #EBEBEB;
    border-radius: 2px;
    input{
        border: none;
        background-color: transparent;
        font-size: 12px;
        line-height: 18px;
        color: $alt-clr;
        width: 100%;
        height: 30px;
        &::placeholder{
            font-size: 12px;
            line-height: 18px;
            color: $alt-clr;
        }
}
    button{
        width: 30px;
        height: 30px;
        svg{
            width: 12px;
            height: 12px;
            stroke: #BABABA;
            fill: transparent;
        }
    }
}

.header-contacts {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 0 10px;
  gap: 10px;
}

.header-phone {
  height: 100%;
  background-color: #1ECF3E;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  color: #ffffff;
  padding: 0 11px;
}

.header-social {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.telegram {
    fill: #2ca5e0;
  }

  &.whatsapp {
    fill: #31db51;
  }
}

.header-burger{
    padding: 20px 10px 20px 10px;
    &__wrap{
        position: relative;
        width: 20px;
        height: 16px;
    }
    span{
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        //background-color: $text-clr;
        background-color: #c60000;
        transition: $tr-time;
        transition-property: top, bottom, opacity, transform;
        &:nth-child(1){
            top: 0;
        }
        &:nth-child(2){
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:nth-child(3){
            bottom: 0;
        }
    }
    &.opened{
        span{
            &:nth-child(1){
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
}

.mobile-menu{
    position: fixed;
    z-index: 999;
    top: 60px;
    bottom: 0;
    left: 100%;
    width: 70%;
    background-color: #fff;
    overflow: scroll;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) $tr-time;
    &::after{
        content: '';
        display: block;
        position: fixed;
        top: 60px;
        left: -100%;
        background-color: #000000;
        opacity: 0.5;
        width: 30%;
        height: 100%;
        transition: $tr-time;
    }
    &.opened{
        left: 30%;
        &::after{
            left: 0%;
        }
    }
    &__wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        height: 100%;
    }
    &__contacts{
        margin: 30px 0 0;
        a{
            display: block;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #1A1A1A;
            margin: 0 0 18px;
            span{
                border-bottom: 1px solid $main-clr-hover;
            }
        }
    }
    &__location{
        font-size: 12px;
        line-height: 16px;
        color: #737373;
    }
}

.mobile-navigation{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FCFCFC;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    z-index: 99; 
    &__wrap{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    &__item{
        flex-direction: column;
        padding: 8px 10px;
        svg{
            width: 20px;
            height: 20px;
            margin: 0 0 5px;
        }
        span{
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            text-align: center;
            color: #1A1A1A;
        }
    }
}
