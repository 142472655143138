.mainbanner {

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    picture {

      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1920/525;
        object-fit: cover;

        @include _768 {
          aspect-ratio: 1/1;
        }

        @media(max-width: 420px) {
          aspect-ratio: 320/480;
        }
      }
    }
  }

  &__slide-text {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 5%;
    left: 15%;
    width: clamp(310px, 30%, 600px);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include _768 {
      top: 10%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: clamp(450px,30%,600px);
    }

    @include _420 {
      justify-content: flex-start;
      margin-top: unset;
      margin-bottom: unset;
      width: clamp(310px, 30%, 600px);
    }

    @media(max-width: 350px) {
      padding-left: 10px;
    }

  }

  &__navigation {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 1%;
    left: 0;
    z-index: 5;

    @include _768 {
      bottom: 5%;
    }
  }

  &__pagination {
    width: fit-content !important;
  }

  &__pagination-bullet {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;

    @include _576 {
      width: 10px; 
      height: 10px; 
    }
  }

  &__pagination-bullet__active {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #c70000;
    border-radius: 50%;
    margin: 0 8px;

    @include _576 {
      height: 10px; 
      width: 10px; 
    }

    @include _768 {
      background-color: #c70000; 
    }
  }


  &__arrow-prev {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4%;

    @include _768{
      display: none;
    }
  }

  &__arrow-next {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 4%;

    @include _768{
      display: none;
    }
  }


  &__title {
    font-size: calcFluid(38, 75);
    color: #ffffff;
    font-weight: 900;
    margin-bottom: 25px;
    line-height: 100%;
  }

  &__description {
    font-size: calcFluid(16, 34);
    color: #fcfcfc;
    font-weight: 400;
    line-height: 100%;
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: calcFluid(15, 21);
    font-weight: 700;
    text-transform: uppercase;
    color: #1a1a1a;
    width: fit-content;
    background-color: #ffffff;
    padding: 5px 15px 5px 20px;
    border-radius: 20px;

    &.default {
      margin-top: auto;
      margin-bottom: 20%;
    }
    
    &.inverted {
      background-color: #c70000;
      color: #ffffff;

      &:after {
       content: '';
       display: inline-block;
       background-image: url('/static/images/banner-arrow-inverted.svg');
       background-repeat: no-repeat;
       background-size: contain;
       margin-left: 8px;
       width: 20px;
       height: 15px;
      }
    }

    &:after {
      content: '';
      display: inline-block;
      background-image: url('/static/images/banner-arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 8px;
      width: 20px;
      height: 15px;
    }

    @include _768 {
      margin-top: auto;
      margin-bottom: 20%;
    }
  }

  &__categories {
    background-image: url(/static/images/brick-background.png);
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 35px 0 35px 0;

    &__wrapper {
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      max-width: 1920px;
      width: 85%;

      @include _1200 {
        width: 95%;
      }

      @include _576 {
        grid-template-columns: repeat(1, 1fr);
      }
    } 

    &__item {
      $this-category-item: &;
      border: 1px solid #1a1a1a;
      padding: 5px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      text-transform: uppercase;
      transition: all 200ms;
      overflow: hidden;

      &:hover {
        border: 1px solid #bababa;
        #{$this-category-item}__title {
          color: $main-clr-hover;
        }

      }

      &__image {
        margin-bottom: 15px;
        position: relative;

        img {
          max-height: 140px;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -35px;
          left: -40px;
          background-image: url(/static/images/category-image-decor.png);
          width: 150%;
          height: 150%;
          background-size: contain;
          background-repeat: no-repeat;
        }

        @include _1200 {
          &__image {
            img {
              width: 85px;
            }

            &::before {
              left: -15px;
              bottom: -15px;
            }

          }
        }
        
        @include _768 {
            img {
              max-height: 50px;
            }

            &::before {
              bottom: -15px;
              left: -20px;
            }
        }
      }

      &__title {
        color: #1a1a1a;
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 8px;
        text-align: center;


        @include _1200 {
          font-size: 18px;
        }

        @include _576 {
          font-size: 14px;
        }
      }


    }

  }

}
