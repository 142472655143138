.theme-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
        width: 36px;
        height: 28px;
        fill: #14A356;
        margin: 0 0 25px;
    }

    span {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        color: #333333;
        @include _992{
            font-size: 24px;
            line-height: 28px;
        }
    }
}


// Basic Modal Styles
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow-y: scroll;
}

.modal__container {
    background-color: #fff;
    padding: 90px 105px;
    max-height: 95vh;
    overflow: auto;
    max-width: 760px;
    @include _992{
        max-width: 576px;
        padding: 25px;
    }
}

.modal__header {}

.modal__content{
    .theme-policy{
        text-align: center;
    }
}

.modal__title {
    font-weight: 700;
    font-size: 34px;
    line-height: 38px;
    color: $text-clr;
    margin: 0 0 30px;
    &--center {
        text-align: center;
    }
    @include _992{
        font-size: 28px;
        line-height: 28px;
    }
}

.modal__subtitle {
    font-weight: normal;
    display: block;
    font-size: 22px;
    line-height: 26px;
    color: $text-clr;
    margin: 10px 0 0;
    @include _992{
        font-size: 14px;
        line-height: 18px;
        margin: 12px 0 0;
        br{display: none;}
    }
}

.modal__close {
    position: absolute;
    cursor: pointer;
    border: none;
    font-size: 38px;
    line-height: 38px;
    background-color: transparent;
    top: 0;
    right: 0;
    padding: 15px 30px;
    color: $text-clr;
    transition: color $tr-time;
    @include _992{
        font-size: 30px;
        line-height: 30px;
        padding: 8px 14px;
    }
    &:hover {
        color: $main-clr-hover;
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        // transform: translateY(15%);
    }

    to {
        // transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        // transform: translateY(0);
    }

    to {
        // transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}