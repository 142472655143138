@import 'vendors/simple-scrollbar';
@import '../content/text';

.seo{
    overflow: hidden;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    &-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 70px;
        height: 100%;
        height: 630px;
        @include _1200{
            grid-column-gap: 30px;
        }
        @include _992{
            grid-column-gap: 15px;
            height: 330px;
        }
        @include _768{
            display: flex;
            flex-direction: column-reverse;
            height: unset;
        }
    }
    &-image{
        img{
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            width: calc(50% - 30px);
            @include _992{
                width: calc(50% - 15px);
            }
            @include _768{
                width: 100%;
                position: static;
            }
        }
    }
    &-text{
        height: 100%;
        overflow: hidden;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            right: 2px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: #DADADA;
        }
        .content-text{
            padding-right: 45px;
        }
        &__wrap{
            overflow: hidden;
            padding: 70px 0px;
            height: 100%;
            @include _992{
                padding: 30px 0;
            }
            @include _768{
                height: 290px;
                padding: 0 0 30px;
            }
        }
    }
}