@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Regular.ttf');
	src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
		url('../fonts/NunitoSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Light.ttf');
	src: local('Nunito Sans Light'), local('NunitoSans-Light'),
		url('../fonts/NunitoSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-LightItalic.eot');
	src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'),
		url('../fonts/NunitoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-LightItalic.woff') format('woff'),
		url('../fonts/NunitoSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-BlackItalic.eot');
	src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'),
		url('../fonts/NunitoSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-BlackItalic.woff') format('woff'),
		url('../fonts/NunitoSans-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-SemiBold.ttf.eot');
	src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
		url('../fonts/NunitoSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Bold.ttf');
	src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
		url('../fonts/NunitoSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-ExtraBold.eot');
	src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
		url('../fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-ExtraBold.woff') format('woff'),
		url('../fonts/NunitoSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-SemiBoldItalic.eot');
	src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
		url('../fonts/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-SemiBoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Black.eot');
	src: local('Nunito Sans Black'), local('NunitoSans-Black'),
		url('../fonts/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-Black.woff') format('woff'),
		url('../fonts/NunitoSans-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-ExtraLightItalic.eot');
	src: local('Nunito Sans ExtraLight Italic'), local('NunitoSans-ExtraLightItalic'),
		url('../fonts/NunitoSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-ExtraLightItalic.woff') format('woff'),
		url('../fonts/NunitoSans-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-ExtraLight.eot');
	src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
		url('../fonts/NunitoSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-ExtraLight.woff') format('woff'),
		url('../fonts/NunitoSans-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-BoldItalic.eot');
	src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'),
		url('../fonts/NunitoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-BoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-ExtraBoldItalic.eot');
	src: local('Nunito Sans ExtraBold Italic'), local('NunitoSans-ExtraBoldItalic'),
		url('../fonts/NunitoSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-ExtraBoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Italic.eot');
	src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
		url('../fonts/NunitoSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/NunitoSans-Italic.woff') format('woff'),
		url('../fonts/NunitoSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
