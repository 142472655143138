.reviews{
    &-container{
        height: 1100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 70px;
        @include _1200{
            grid-column-gap: 30px;
        }
        @include _992{
            grid-column-gap: 15px;
        }
        @include _768{
            grid-template-columns: 1fr;
            grid-row-gap: 30px;
            display: flex;
            flex-direction: row;
            height: auto;
        }
        @media screen and (max-width:767) {
            
        }
        @media screen and (max-width:460px) {
            display: flex;
            flex-direction: column;
            height: auto;
        }

    }
    .page-title {

      &__name {
        align-items: center;
        color: #d5d5d5;
        width: 100%;

        span {
        font-size: 83px;
        text-align: justify;
        text-transform: uppercase;
        line-height: 107px;

        @include _1650 {
          font-size: 50px; 
        }

        @include _1024 {
          text-align: center;
          font-size: 32px;
          line-height: 48px;
        }
        }
      }
    }
    &-block{
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        &:nth-child(1){
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width:767px){
                display: block;
                margin-bottom: 5px;
            }
            @media screen and (max-width:460px){
                display: block;
                margin-bottom: 5px;
            }
           
        }
        @media screen and (max-width:767px){
            // width: 50%;
            max-height: 360px;
            img{
                display: block !important;
                width: 100%;
            }
            .elfsight-app-236d36b8-f98b-4092-bc82-2981c04b8b3f{
                display: none;
            }
        }
        @media screen and (max-width:460px){
            // width: 50%;
            max-height: 360px;
            img{
                display: block !important;
                width: 100%;
            }
            .elfsight-app-236d36b8-f98b-4092-bc82-2981c04b8b3f{
                display: none;
            }
        }
        iframe{
            max-width: 760px;
            width: 100%;
            height: 100%;
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            @media screen and (max-width:767px){
                display: none;
            }
        }

    }
}


.Main__Container-sc-1n4ud0o-0.kzQnoI>a {
    display: none !important;
}
