body{
	min-height: 100vh;
	margin: 0;
	color: $text-clr;
	font-family: 'Nunito Sans' !important;
	display: flex;
	flex-direction: column;
	position: relative;
	&.opened{
		overflow: hidden;
	}
}
main{
	position: relative;
	flex: 1 0 auto;
}
p,ol,ul,h1,h2,h3,button,form{
	margin: 0;
	padding: 0;
}
em{
	font-style: normal;
	color: $main-clr-hover;
}
button, input, textarea{
	font-family: inherit;
	&:focus{
		outline: none;
	}
}
input{
	min-width: 0;
	font-size: inherit;
	appearance: none;
}
ul, li{
	list-style-type: none;
}

input::-webkit-input-placeholder{
	font-size: 15px;
	line-height: 18px;
	color: #999999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
a{
	color: $text-clr;
	text-decoration: none;
	transition: color $tr-time;
	&:hover{
		color: var(--main);
	}
}
svg{
	vertical-align: middle;
	transition: fill $tr-time;
}
picture{
	display: block;
}
img{
	display: block;
}
source{
	display: none;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}
.content{
	&-seo{
		overflow: hidden;
		position: relative;
		max-width: 1920px;
		margin: 50px auto 0px auto;
		&__wrapper{
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 70px;
			height: 100%;
			height: 630px;
			@include _1200{
				grid-column-gap: 30px;
			}
			@include _992{
				grid-column-gap: 15px;
				height: 330px;
			}
			@include _768{
				display: flex;
				flex-direction: column-reverse;
				height: unset;
			}
		}
		&__text{
			overflow: hidden;
            padding: 70px 0px;
            height: 100%;
            @include _992{
                padding: 30px 0;
            }
            @include _768{
                height: 290px;
                padding: 0 0 30px;
            }
		}
		&__image{
			img{
				// height: 100%;
				object-fit: cover;
				position: absolute;
				left: 50%;
				width: calc(50% - 30px);
				@include _992{
					width: calc(50% - 15px);
				}
				@include _768{
					width: 100%;
					position: static;
				}
			}
		}
	}
}

.sw-app {
	padding-bottom: 0 !important;
}
