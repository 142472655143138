.container{
	max-width: 1650px;
	margin: 0 auto;
	padding: 0 15px;
}

.loading{
	opacity: .4;
	pointer-events: none;
	user-select: none;
}

.show-after-load{
	opacity: 0 !important;
	visibility: hidden !important;
}

.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	
	&--account{
		padding: 20px 20px;
		svg{
			width: 26px;
			height: 26px;
			fill: $text-clr;
			margin-right: 14px;
			transition: fill $tr-time;
		}
		span{
			font-size: 18px;
			line-height: 26px;
			color: $text-clr;
			transition: color $tr-time;
		}
		&:hover{
			svg{
				fill: $main-clr-hover
			}
			span{
				color: $main-clr-hover;
			}
		}
	}

	&--filled{
		background-color: $main-clr;
		transition: background-color $tr-time;
		border-radius: 3px;
		span{
			font-weight: 700;
			font-size: 22px;
			line-height: 26px;
			color: #FCFCFC;
			@include _992{
				font-size: 16px;
				line-height: 22px;
			}
		}
		&:hover{
			background-color: $main-clr-hover;
		}
	}

	&--gray{
		background-color: transparent;
		transition: border-color $tr-time;
		border: 1px solid #BABABA;
		border-radius: 3px;
		span{
			font-weight: 700;
			font-size: 22px;
			line-height: 26px;
			color: #BABABA;
			transition: color $tr-time;
			@include _992{
				font-size: 16px;
				line-height: 22px;
			}
		}
		&:hover{
			border-color: #737373;
			span{
				color: #737373;
			}
		}
	}

	&--act{
		$this: &;
		&.no-name{
			padding: 10px 12px;
		}
		&__icon{
			overflow: hidden;
			position: relative;
			svg{
				width: 28px;
				height: 28px;
				transition: $tr-time;
				&:nth-child(1){
					position: relative;
					fill: $text-clr;
				}
				&:nth-child(2){
					fill: $main-clr;
					position: absolute;
					left: 0;
				}
				@include _992{
					width: 25px;
					height: 25px;
				}
			}
		}
		&__name{
			margin-left: 14px;
			span, h1{
				font-size: 18px;
				line-height: 26px; 
				color: $text-clr;
				transition: color $tr-time;
			} 
			h1{
				color: #a22020;
			}
		}
		
		&:hover{
			#{$this}__icon{
				svg{
					fill: $main-clr-hover;
				}
			}
			#{$this}__name{
				span{
					color: $main-clr-hover;
				}
			}
		}

		&[data-state='default']{
			#{$this}__icon{
				svg{
					&:nth-child(1){
						opacity: 1;
						top: 0;
					}
					&:nth-child(2){
						opacity: 0;
						top: 25px;
					}
				}
			}
		}
		&[data-state='added']{
			#{$this}__icon{
				svg{
					&:nth-child(1){
						opacity: 0;
						top: -25px;
					}
					&:nth-child(2){
						opacity: 1;
						top: 0;
					}
				}
			}
		}
	}

	&--border{
		border: 1px solid $main-clr;
		border-radius: 3px;
		transition: border-color $tr-time;
		span{
			font-weight: 700;
			font-size: 22px;
			line-height: 26px;
			color: $main-clr;
			transition: color $tr-time;
		}
		&:hover{
			border-color: $main-clr-hover;
			span{
				color: $main-clr-hover;
			}
		}
	}

	&--swal{
		padding: 15px 20px;
		margin: 0 5px;
	}
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}

.breadcrumbs{
	padding: 30px 0 60px;
	@include _992{
		padding: 16px 0 20px;
	}
	&-item{
		font-size: 18px;
		line-height: 26px;
		color: $text-clr;
		@include _992{
			font-size: 14px;
			line-height: 28px;
		}
		&--link{
			transition: color $tr-time;
			&:hover{
				color: $main-clr-hover;
			}
		}
		&--sep{
			color: $main-clr;
			padding: 0 3px;
		}
	}
	&-back{
		display: block;
		padding: 30px 0 0;
	}
}

.page-title{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 30px;
	@include _992{
		margin: 0 0 20px;
	}
	&__name{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		@include _992{
			align-items: flex-start;
		}
		span, h1{
			display: block;
			font-weight: 600;
			font-size: 48px; 
			line-height: 52px;
			text-transform: uppercase;
			@include _992{
				font-size: 24px;
				line-height: 28px;
			}
		}
		s{
			text-decoration: none;
			text-align: right;
			font-weight: 400;
			font-size: 18px;
			line-height: 28px;
			color: #919191;
			padding: 6px 0 0;
		}
	}
	&__next{}

	&--desktop{
		@include _992{
			.page-title__next{
				display: none;
			}
		}
	}
	&--mobile{
		display: none;
		margin: 30px 0 0;
		@include _992{
			display: flex;
			justify-content: center;
		}
	}
}

.page-section{
	padding: 0 0 100px;
	@include _992{
		padding: 0 0 50px;
	}
}

.action-link{
	display: flex;
	flex-direction: row;
	align-items: center;
	&--back{
		svg{
			margin-right: 28px;
			margin-left: 0;
			@include _992{
				margin-right: 12px;
			}
		}
	}
	&--forward{
		flex-direction: row-reverse;
		svg{
			transform: rotate(180deg);
			margin-left: 14px;
			margin-right: 0;
			@include _992{
				margin-left: 10px;
			}
		}
	}
	svg{
		width: 20px;
		height: 20px;
		fill: $main-clr;
		transition: fill $tr-time;
		@include _992{
            width: 14px;
			height: 14px;
        }
	}
	span{
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		letter-spacing: -0.5px;
		color: $main-clr;
		transition: color $tr-time;
		@include _992{
            font-size: 16px;
			line-height: 20px;
        }
	}
	&:hover{
		svg{
			fill: $main-clr-hover;
		}
		span{
			color: $main-clr-hover;
		}
	}
}

.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-pagination{
		position: relative;
		z-index: 1;
		&-bullet{
			position: relative;
			z-index: 2;
			background-color: #BABABA;
			width: 12px;
			height: 12px;
			border-radius: 0px;
			margin: 0 15px;
			transition: $tr-time;
			&::before{
				content: '';
				display: block;
				position: absolute;
				z-index: 1;
				width: 23px;
				height: 23px;
				border: 1px solid $main-clr;
				top: -4px;
				left: -4px;
				opacity: 0;
				transition: opacity $tr-time;
			}
			&-active{
				width: 15px;
				height: 15px;
				background-color: $main-clr;
				&::before{
					opacity: 1;
				}
			}
		}
	}
	&-navigation{
		cursor: pointer;
		border-top: 1px solid #737373;
		border-right: 1px solid #737373;
		transition: border-color $tr-time;
		width: 18px;
		height: 18px;
		position: relative;
		top: -2px;
		&:hover{
			border-color: $main-clr;
		}
		&__next{
			transform: rotate(45deg);
			&.wow{
				width: 30px;
				height: 30px;
			}
		}
		&__prev{
			transform: rotate(225deg);
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.simple-counter{
    cursor: pointer;
	user-select: none;
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    background: #FFFFFF;
	align-items: center;
	@include _992{
		grid-template-columns: 18px 1fr 18px;
	}
    &__minus,
    &__plus{
        svg{
			width: 28px;
			height: 28px;
			fill: $text-clr;
			transition: fill $tr-time;
			@include _992{
				width: 18px !important;
				height: 18px !important;
			}
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
    }
    &__field{
        border: none;
        text-align: center;
        font-weight: 700;
		font-size: 32px;
		line-height: 38px;
        color: $text-clr;
		@include _992{
			font-size: 22px !important;
			line-height: 26px !important;
		}
    }
}

.swal2-title{
	@include _576{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _576{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _576{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}


.fancybox__thumbs .carousel__slide .fancybox__thumb::after{
	border-color: $main-clr !important;
}