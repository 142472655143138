.about-us {
  
  &__wrapper {
    margin-left: 3%;
    display: flex;
  
    @include _1200 {
      margin-left: 0;
      justify-content: center;
    }
  }

  &__part {
    
    &.left {
      min-width: 345px;
      width: 30%;
      
      @include _1200 {
        min-width: 285px;
      }
      
      @include _768 {
        display: none;
      }

    }

    &.right {
      width: 65%;
      margin-left: 5%;
    
      @include _1200 {
        width: 55%;
      }
      
      @include _768 {
        width: 100%;
        margin-left: 0;
      }

    }

  }

  &__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 75px;
    text-align: end;
    margin-bottom: 35px;
    text-transform: uppercase;
  
    @include _1200 {
      font-size: 48px;
      margin-bottom: 20px;
      line-height: 52px;
    }

    &.mobile {
      display: none;
      font-size: 24px;
      font-weight: 600;
      text-align: start;
      line-height: 31px;
      width: 50%;
      margin-bottom: 15px;
      
    @include _768 {
      display: block;
    }

    }

  }

  &__description {
    font-size: 21px;
    font-weight: 400;
    line-height: 27px;

    &.short {
      text-align: right;

    }

    &.long {
      margin: 10px 0 35px 0;
      
      @include _768 {
        display: none;
      }

    }

    &.mobile {
      display: none;
      overflow-y: scroll;
      height: 170px;
      padding-right: 10%;
      font-size: 15px;
      line-height: 15.6px;
      margin-bottom: 25px;

      @include _768 {
        display: block;
      }

    }

    span {
      font-weight: 700;
    }
  }

  &__description-decor {
    margin-bottom: 20px;
  }


  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    margin-top: 50px;
  }

  &__buttons-mobile {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
      
    @include _768 {
        display: flex;
    }

  }

  &__read-more {
    background-color: #c70000;
    color: #f7f7f7;
    font-size: 21px;
    font-weight: 700;
    padding: 18px 0;
    border-radius: 5px;
    text-align: center;
    margin-left: 25px;
    transition: all 50ms;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #f7f7f7;
      background-color: #f40000;
    }
    
    @media(max-width: 1500px) {
      font-size: 15px;
    }

    @include _1200 {
      padding: 11px 0;
    }
    
    @include _768 {
      margin: 0;
      width: 95%;
    }

  }

  &__download-prices {
    box-sizing: border-box;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    border: 3px solid #c70000;
    padding: 15px 0;
    transition: all 50ms;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    svg {
      fill: #1a1a1a;
    }

    &:hover {
      color: #c70000;
        svg {
          fill: #c70000;
        }
    }
    
    @media(max-width: 1500px) {
    font-size: 15px;

      svg {
        width: 25px;
        height: 25px;
      }

    }
    
    @include _1200 {
      padding: 8px 0;
    }
    
    @include _768 {
      justify-content: center;
      margin-bottom: 10px;
      width: 95%;

      svg {
        margin-left: 5px;
      }
    }

  }

  &__slider {
    overflow: hidden;
  }

  &__slide {
    width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: 65px;

    @media(max-width: 1674px) {
      margin-top: 35px; 
    }

    @include _576 {
      margin-top: 15px; 
    }
  }

  &__arrow-prev {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4%;

    @include _768{
      display: none;
    }
  }

  &__arrow-next {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 4%;

    @include _768{
      display: none;
    }
  }
  
  &__pagination {
    width: auto !important;
  }

  &__pagination-bullet {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;

    @include _576 {
      width: 10px; 
      height: 10px; 
    }
  }

  &__pagination-bullet__active {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #c70000;
    border-radius: 50%;
    margin: 0 8px;

    @include _576 {
      height: 10px; 
      width: 10px; 
    }

    @include _768 {
      background-color: #c70000; 
    }
  }

}
