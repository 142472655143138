.footer-form {
  position: relative;
  background-image: url(/static/images/brick-background.png);
  background-size: cover;
  //margin-top: 100px;
  margin-top: clamp(30px, 10vw, 200px);
  padding: 50px 0;

  @include _768 {
    margin-top: clamp(30px, 30vw, 200px);
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    grid-column-gap: 5%;

    @include _768 {
      grid-template-columns: 1fr;
    }
  }

  &__part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    @include _768 {
      align-items: center;
    }
  }

  &__image {
    //width: 100%;
    height: 115%;
    //margin-top: -35%;
    margin-top: -20%;

    img {
      width: 100%;
    }

    @include _768 {
      padding: 0 3%;
    }
    
    @include _420 {
      margin-top: -25%;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 125%;
    font-weight: 600;
    width: 65%;
    margin-bottom: 20px;
    text-transform: uppercase;

    @include _768 {
      width: 95%; 
      font-size: 24px;
      margin-top: 50px;
      text-align: center;
    }

    @include _420 {
      margin-top: 20px;
    }

  }

  &__subtitle {
    font-size: 21px;
    line-height: 125%;
    width: 65%;
    margin-bottom: 25px;

    @include _768 {
      text-align: center;
    }

    @include _420 {
      font-size: 12px;
      width: 95%;
    }
  }

}
