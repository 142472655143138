.gallery {
  margin-top: 140px;

  @include _768 {
    margin-top: 55px;
  }


  &__title {
    color: #1a1a1a;
    font-weight: 600;
    font-size: 57px;
    line-height: 74px;
    margin: 0 auto 45px auto;
    width: fit-content;

    @include _576 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: 65px;

    @media(max-width: 1674px) {
      margin-top: 35px; 
    }

    @include _576 {
      margin-top: 15px; 
    }
  }

  &__arrow-prev {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 4%;

    @include _768{
      display: none;
    }
  }

  &__arrow-next {
    width: 23px;
    height: 42px;
    cursor: pointer;
    background-image: url('/static/images/icon_arrow_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 4%;

    @include _768{
      display: none;
    }
  }

  &__pagination {
    width: auto !important;
  }

  &__pagination-bullet {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;

    @include _576 {
      width: 10px; 
      height: 10px; 
    }
  }

  &__pagination-bullet__active {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #d9d9d9;
    border: 1px solid #c70000;
    border-radius: 50%;
    margin: 0 8px;

    @include _576 {
      height: 10px; 
      width: 10px; 
    }

    @include _768 {
      background-color: #c70000; 
    }
  }

  &__swiper {
    max-height: 425px;
    height: auto;
    //overflow: hidden;
    overflow-x: clip;
    margin: 0 auto;

    @media(max-width: 1674px) {
      aspect-ratio: 290/174;
      height: auto;
    }

  }


  &__slide {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 715/425;
    }

    &.swiper-slide {
      width: 435px;
    }
    &.swiper-slide-prev {
    }
    &.swiper-slide-next {
    }
    &.swiper-slide-active {
      transform: scale(0.847, 0.85) !important; 

      @media(max-width: 1674px) {
      transform: scale(0.847, 1) !important; 
      }
    }
    

  }

  &__link-wrapper {
    display: flex;
    justify-content: center;
  }

  &__link {
    color: #fcfcfc;
    background-color: #c70000;
    font-size: 21px;
    font-weight: 700;
    padding: 15px 45px;
    margin-top: 15px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 3px;

    &:hover {
      color: #fcfcfc;
      background-color: #f40000;
    }

    @include _768 {
      display: none;
    }
  }
}
